<template>
  <div>
    <ColorTextBtn size="small" @click="showDialog"> SKU关联关系 </ColorTextBtn>
    <DetailForm
      ref="form"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :isAdd="false"
      :data="data"
      title="SKU关联"
    />
  </div>
</template>

<script>
import DetailForm from './form'
import { commonEheaderMixin } from '@/mixins'

export default {
  mixins: [commonEheaderMixin],
  components: { DetailForm },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
