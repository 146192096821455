export const tableOption = {
  search: true,
  height: '100%',
  menuWidth: 150,
  menuAlign: 'center',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSpan: 6,
  selection: false,
  searchLabelWidth: 90,
  column: [
    {
      label: '产品名称',
      prop: 'combinedChineseName',
      search: true,
      hide: true
    },
    {
      label: '组合产品信息',
      prop: 'combinedInfo',
      slot: true
    },
    {
      label: '组合产品',
      prop: 'combinedProduct',
      minWidth: 100,
      slot: true
    },
    {
      label: '颜色',
      prop: 'combinedColorName',
      slot: true
    },
    {
      label: '尺码',
      prop: 'combinedSizeName',
      minWidth: 100,
      slot: true
    },

    {
      label: '业务员',
      prop: 'createByName',
      search: true
    },

    {
      label: '自定义编码',
      prop: 'combinedCustomProductCode',
      search: true,
      hide: true
    },

    {
      label: '内部编码',
      prop: 'combinedSystemProductCode',
      search: true,
      hide: true
    },

    {
      label: '款式',
      prop: 'combinedSizeName',
      search: true,
      hide: true
    },

    {
      prop: 'create_time',
      search: true,
      searchSpan: 9,
      searchLabel: '时间',
      searchFormSlot: true,
      hide: true
    },

    {
      label: '时间',
      prop: 'createTime'
    }
  ]
}

