<template>
  <!-- 组合产品管理 -->
  <div class="app-container">
    <avue-crud
      ref="crud"
      class="flex-one-page"
      :option="tableOption"
      :data="finalData"
      :page="tablePage"
      :tableLoading="tableLoading"
      :spanMethod="spanMethod"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #create_timeSearchForm="{ form }">
        <SaleDateTime :time.sync="form['create_time']" @change="searchChange"></SaleDateTime>
      </template>

      <template #combinedInfo="{ row }">
        <div>
          <span>中文名称：</span>
          <span>{{ row.combinedChineseName }}</span>
        </div>
        <div>
          <span>英文名称：</span>
          <span> {{ row.combinedEnglishName }}</span>
        </div>
        <div>
          <span>自定义编码：</span>
          <span> {{ row.combinedCustomProductCode }}</span>
        </div>
      </template>

      <template #menu="{ row }">
        <RelationDetail v-if="hasRelationDetail" :sup_this="sup_this" :data="row" />
      </template>
    </avue-crud>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import SaleDateTime from '@/components/saleDateTime'

import RelationDetail from './module/relationDetail'

import { tableOption } from './const'
import { initData } from '@/api/data'

import cloneDeep from 'lodash/cloneDeep'
import { getSpanMethod } from '@/utils'

export default {
  mixins: [
    avueCrud({
      tableOption
    })
  ],

  components: {
    SaleDateTime,
    RelationDetail
  },

  props: {
    url: {
      type: String,
      default: '/externaladmin/productService/combinedProductPrototype/list'
    },

    resetTableOption: {
      type: Object,
      required: true
    },

    resetMergeData: {
      type: Object,
      default: () => ({})
    },

    spanLevelList: {
      type: Object,
      default: () => ({})
    },

    //关联详情
    hasRelationDetail: Boolean
  },

  data() {
    return {
      sup_this: this,
      tableOption: { ...tableOption, ...this.resetTableOption }
    }
  },

  computed: {
    finalData({ tableData }) {
      const cloneData = cloneDeep(tableData)
      const tmpArr = []
      cloneData.map((item, index) => {
        const combinedCustomProductList = $GET(item, 'combinedCustomProductList', [])
        combinedCustomProductList.map((product, productIndex) => {
          const { combinedColorName, id: combinedColorId } = product
          const combinedCustomSpecificProductList = $GET(product, 'combinedCustomSpecificProductList', [])
          combinedCustomSpecificProductList.map((specificInfo, specIndex) => {
            const { combinedSizeName, combinationCustomSku } = specificInfo
            tmpArr.push({
              ...item,
              $combinedCustomSpecificProductItem: specificInfo,
              $combinedCustomProductItem: product,
              combinedSizeName,
              combinationCustomSku,
              combinedColorName,
              combinedColorId,
              $spanLevelList: {
                level1:
                  specIndex == 0 && productIndex == 0
                    ? combinedCustomProductList.length * combinedCustomSpecificProductList.length
                    : 0,
                level2: specIndex == 0 ? combinedCustomSpecificProductList.length : 0
              }
            })
          })
        })
      })
      return tmpArr
    }
  },

  methods: {
    spanMethod({ row, column }) {
      return getSpanMethod({ row, column, spanLevelList: this.spanLevelList })
    },

    beforeInit() {
      this.tableData = []
    },

    getList(data) {
      return initData(this.url, 'post', { ...this.resetMergeData, ...data })
    }
  }
}
</script>
