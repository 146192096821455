export const tableOption = {
  search: true,
  height: '100%',
  menuAlign: 'center',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSpan: 6,
  selection: false,
  searchLabelWidth: 120,
  menu: false,
  column: []
}
