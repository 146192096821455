<template>
  <BaseDialog minHeight="200px" :title="title" :dialogVisible.sync="dialog" width="1300px" :hiddenFooter="true">
    <avue-crud
      style="max-height: 500px"
      class="flex-one-page"
      ref="crud"
      :key="key"
      :option="tableOption"
      :data="currFinalData"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #createByName="{ row }">
        <span v-if="row.mainUser">{{ `${row.mainUser} - ${row.createByName}` }}</span>
        <span v-else>{{ row.createByName || '暂无' }}</span>
      </template>

      <template #[prop]="{ row }" v-for="({ prop }, index) in fillColumn">
        <div :key="`${row.id}_${index}`">
          <div>
            <span>自定义：</span>
            <span>{{ skuInfo(row, index).customSku }}</span>
          </div>

          <div>
            <span>内部：</span>
            <span>{{ skuInfo(row, index).internalSku }}</span>
          </div>
        </div>
      </template>
    </avue-crud>
    <template #footer>
      <span></span>
    </template>
  </BaseDialog>
</template>
<script>
import avueCrud from '@/mixins/avueCrud'

import { tableOption } from './const'
import { designCombinedProductDetail as getList } from '@/api/product/combinationPoductApi'

import { commonFromMixin } from '@/mixins'

import { getUUID } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'

export default {
  mixins: [
    commonFromMixin,
    avueCrud({
      tableOption,
      getList
    })
  ],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      key: getUUID(),
      tableOption: cloneDeep(tableOption),
      finalData: [],
      fillColumn: [],
      resetMergeData: {
        id: this.data && this.data.id
      }
    }
  },

  computed: {
    skuInfo() {
      return (row, index) => {
        const obj = $GET(row, 'combinedCustomSpecificProductItemList', [])[index] || {}
        return obj
      }
    },

    currFinalData({ finalData, pageIndex, pageSize }) {
      return finalData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
    }
  },

  methods: {
    beforeInit() {
      this.finalData = []
    },

    afterInit() {
      console.log('afterInitafterInit')
      this.getData()
    },

    sizeChange(pageSize) {
      this.pageSize = pageSize
      this.tablePage.pageSize = pageSize
    },

    pageChange(pageIndex) {
      this.pageIndex = pageIndex
      this.tablePage.pageIndex = pageIndex
    },

    sortAndFillTableData(data) {
      const productPrototypeCollectionList = $GET(data, 'productPrototypeCollectionList', [])
      $GET(data, 'combinedCustomProductList', []).map((product) => {
        $GET(product, 'combinedCustomSpecificProductList', []).map((specificInfo) => {
          specificInfo.combinedCustomSpecificProductItemList = sortBy(
            $GET(specificInfo, 'combinedCustomSpecificProductItemList', []),
            ['collectionId']
          )
          specificInfo.combinedCustomSpecificProductItemList.map((specificProductItem) => {
            const { collectionId } = specificProductItem
            const findItem = productPrototypeCollectionList.find(({ id }) => {
              return id == collectionId
            })
            //填充找到的产品
            specificProductItem.$productPrototypeCollectionItem = findItem
          })
        })
      })
    },

    getData() {
      //排序保证顺序一致
      this.sortAndFillTableData(this.tableData)
      const cloneData = cloneDeep(this.tableData)
      const tmpArr = []
      this.tableOption = cloneDeep(tableOption)
      this.fillColumn = []
      $GET(cloneData, 'combinedCustomProductList', []).map((product, combinedCustomProductIndex) => {
        const combinedCustomSpecificProductList = $GET(product, 'combinedCustomSpecificProductList', [])
        combinedCustomSpecificProductList.map((specificInfo, specificIndex) => {
          tmpArr.push({
            ...specificInfo,
            $combinedCustomProductItem: product
          })
          if (specificIndex == 0 && combinedCustomProductIndex == 0) {
            $GET(specificInfo, 'combinedCustomSpecificProductItemList', []).map(
              (combinedCustomSpecificProductItem, specificProductItemIndex) => {
                this.fillColumn.push({
                  label: $GET(combinedCustomSpecificProductItem, '$productPrototypeCollectionItem.name', ''),
                  prop: `sku_${specificProductItemIndex}`,
                  slot: true
                })
              }
            )
          }
        })
      })

      this.tableOption.column.push(...this.fillColumn)

      this.finalData = tmpArr
      this.tablePage.total = tmpArr.length
      //重新生成table刷新table高度
      this.key = getUUID()
    }
  }
}
</script>
